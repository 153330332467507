body {
    background-color: #fff;
    background-image: url("images/background.png");
    background-position: top center;
    background-repeat: no-repeat;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}
.container {
    display: grid;
    /*grid-template-columns: 3fr 1fr;*/
    grid-template-rows: auto;
    /*grid-template-areas:*/
    /*        "header header"*/
    /*        "main-content-left main-content-right"*/
    /*        "footer footer"*/
    grid-template-areas:
            "header"
            "main-content"
            "footer"
}
.header {
    grid-area: header;
    display: flex;
    justify-content: center;
}
.main-content {
    grid-area: main-content;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "main-content-left main-content-right";
    background-image: url("images/background-main.png");
    min-height: 227px;
    background-size: cover;
}

@media (max-width: 767.98px) {
    .main-content {
        grid-template-columns: 1fr;
        grid-template-areas:
                "main-content-left"
                "main-content-right"
    }
}

.main-content-left  {
    grid-area: main-content-left;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-content-left h1 {
    padding: 2rem 2rem;
    font-size: 4rem;
    font-weight: 300;
}

@media (max-width: 767.98px) {
    .main-content-left h1 {
        padding: 3rem 2rem;
        font-size: 3rem;
    }
}

.main-content-right {
    grid-area: main-content-right;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.7);
    color: #111;
    font-weight: 300;
    text-align: center;
    font-size: 1.5rem;
}
.main-content-right p.iconic {
    font-size: 2rem;
}

@media (max-width: 767.98px) {
    .main-content-right {
        font-size: 2rem;
    }
}

.footer {
    grid-area: footer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer address {
    text-align: center;
    color: darkgray;
    padding: 2rem;
    font-size: 0.75rem;
}
.footer address > p {
    margin-bottom: 0.25rem;
}
.footer address a {
    color: darkgray;
}
.footer address a#email {
    display: flex;
    align-items: center;
}
.footer address a#email canvas {
    display: inline-block;
}

.rainbow-text {
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);   -webkit-background-clip: text;
    color: transparent;
    font-weight: 400;
    text-shadow:
            -0.1px -0.1px 0 #fff,
            0.1px -0.1px 0 #fff,
            -0.1px 0.1px 0 #fff,
            0.1px 0.1px 0 #fff;
}